<!--
 * @FileDescription:网盟用户端官网
 * @Author: 蔡林泽
 * @Date: 2021/2/12
 * @LastModifiedTime: 2021/2/12
 -->
<template>
  <WebsiteNavigation>
    <template slot="content">
      <div class="main">
        <h1 style="margin:50px 0">Affiliate Program Terms</h1>
        <div>
          <p>
            This affiliate agreement contains the partnership terms and conditions between you and KakaClo, involving your application to become a KakaClo.com affiliate customer. By applying to participate in the KakaClo affiliate plan, you confirm that you have read this agreement and agree to be bound by its terms and conditions.
            THE BASICS
            <br>1.<br>
            To join the affiliate project, you need to register for a free KakaClo affiliate account, get approval and generate an affiliate link URL. After obtaining the affiliate qualification on our website, your account will be immediately activated in our plan. By registering for the KakaClo affiliate plan, you agree to:
            You have reached the legal age, and there is no legal reason to prevent or prohibit you from promoting products as a member and earning commissions from these promotions;
            Your promotional content and/or website do not violate any laws;
            Your promotional content is not defamatory and does not infringe the rights of any entity;
            Your promotional content does not infringe any copyright;
            You will perform all business activities in an ethical and legal manner.
            <br>2.<br>
            As an affiliate member, you can choose any form of promotion that complies with the terms of this agreement. However, you must not:
            You cannot register a KakaClo account through your own affiliate link and use the KakaClo account to place orders on our platform;
            Violation of promotional regulations;
            Directly promote affiliate links in any pay-per-click campaigns through Google ads, Bing ads, etc.; use any KakaClo trademark through search engine keywords, or use any KakaClo trademark or similar and confusing brand terms with KakaClo trademarks to initiate pay-per-click ads series.
            Send unsolicited emails or other messages that may constitute spam;
            Block referring websites/links, use deceptive redirect links, use SEO-unfriendly websites or websites with incorrect content, deceptive or other misleading websites;
            Do anything that may violate any law, including but not limited to privacy, intellectual property rights and spam;
            Engage in any behavior that does not meet our "fair competition" standards, including fraud, confusing new users, bait or providing links and content designed to mislead customers.
            <br>3.<br>
            We reserve the right to reject any recommendation and commission. Once we find that you have violated the above regulations, we have the right to freeze your affiliate account and we will notify you by email, but you can still unblock the account by appealing, and you need to fill in the appeal form. Including but not limited to the following content, the pictures, links, content and your instructions you use to promote KakaClo, after we review and approve, you can continue to use your affiliate account normally. Please include as much details as possible.
            Please abide by the code of ethics, follow the rules, and we will do our best to provide you with the most comprehensive support so that you can safely enjoy income in our plan.
            COMMISSION AND COMMISSION WITHDRAWAL
            <br>1.Commission = (Amount of Total Orders - Amount of Disputed Orders) x 2%
            Total Orders includes both dropshipping orders and wholesale orders.
            Shipping fee will also be included.
            Disputes happen when the payment is not received by KakaClo when a deal closes.
            <br>2.We track orders from a user who comes from your link for an entire year, and pay the commission accordingly. The day that the user places his first order will be considered as day 1, meaning that we don’t start the countdown until an order has been placed by this specific user.
            <br>3. You may start withdrawing your commission once you convert at least 10 leads, meaning 10 customers who have placed orders from KakaClo.
            <br>4. You may withdraw daily. Your commission will be updated at 0:00 (UTC+8) every day including the addition from new orders and subtraction from previous disputed orders.
            <br>5.You will receive your commission by PayPal, Payoneer, or KakaClo Wallet in 1-3 working days after you click the Withdraw button.PRIVACY POLICY
            KakaClo respects the privacy of all its members and partners. Without your explicit permission, we will not share or sell any of your information with any third party. All your personal information has been safely stored and is only used for us to keep you informed about our membership system, our offers, to send you notifications and to ensure that you get paid.
            <span class="weight">
              SCOPE OF RESPONSIBILITY
            </span>
            <span class="color">
              KakaClo shall not be liable for data loss and/or any damages arising from, based on, or due to this agreement, except as expressly provided by law, including direct, indirect, special, incidental or punitive damages or any other form of damages, even if KakaClo has been informed of the possibility of such damages. In addition, our total liabilities for this agreement and distribution plan will not exceed the total commissions paid or payable to you under this agreement. We do not make any express or implied guarantees or statements to ensure that the operation of our website will not be interrupted or error-free, and we are not responsible for the consequences of any interruption or error.
            </span>
            <span class="weight">
              CONTACT US
            </span>
            <span class="color">
              If you have any questions about the above points, please contact us at support@kakaclo.com
            </span>
          </p>
        </div>
      </div>
    </template>
  </WebsiteNavigation>
</template>

<script>
import WebsiteNavigation from '@/components/layout/WebsiteNavigation.vue'
// import API from '@/api/index'
export default {
  components: { WebsiteNavigation },
  data() {
    return {
    }
  },
  created() { },
  activated() { },
  methods: {}
}
</script>

<style lang="scss" scoped>
.main {
  width: 1400px;
  margin: 0 auto;
  h1 {
    font-size: 40px;
    font-weight: 500;
    color: #121e26;
    text-align: center;
    // width: 1150px;
  }
  p {
    color: #606060;
    line-height: 30px;
    margin-bottom: 100px;
  }
  .weight{
    font-size: 16px;
    font-weight: bold;
    display: block;
    margin-top: 10px;
    color: #10141d;
  }
  .color{
    color: #10141d;
  }
}
</style>
